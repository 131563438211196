import { useOidcUser } from '@axa-fr/react-oidc';
import { TextInput, InputBase } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Select } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { default as PhoneInput } from 'react-phone-number-input/input';

import { useGetCountries } from 'api/queries/countries/countries';
import { useGetIndustrialSectors } from 'api/queries/industrial-sectors/industrial-sectors';
import { InvitationSchemaType, RequestSchemaType } from './companyFormSchema';

type Props = {
    form: Pick<UseFormReturnType<RequestSchemaType | InvitationSchemaType>, 'getInputProps' | 'isDirty' | 'validateField'>;
    readOnly?: boolean;
};
export const CompanyBaseData = ({ form, readOnly }: Props) => {
    const { t } = useTranslation();
    const { oidcUser } = useOidcUser();
    const { data: countriesResponse, isPending: areCountriesLoading } = useGetCountries();
    const { data: industrialSectorsResponse, isPending: areIndustrialSectorsLoading } = useGetIndustrialSectors({
        query: { meta: { headers: { 'Accept-Language': oidcUser.locale } } }
    });

    const countries = countriesResponse?.data || [];
    const industrialSectors = industrialSectorsResponse?.data || [];

    const uidProps = form.getInputProps('companyData.uidNumber');

    return (
        <>
            <div className="flex gap-4">
                <TextInput
                    className="flex-grow"
                    label={t('companyName')}
                    placeholder={t('enterPlaceholder', { label: t('companyName') })}
                    readOnly={readOnly}
                    required
                    {...form.getInputProps('companyData.companyName')}
                />
                <TextInput
                    className="flex-grow"
                    label={t('additionalCompanyName')}
                    placeholder={t('enterPlaceholder', { label: t('additionalCompanyName') })}
                    readOnly={readOnly}
                    {...form.getInputProps('companyData.additionalCompanyName')}
                />
            </div>
            <Select
                comboboxProps={{ zIndex: 9999 }}
                data={industrialSectors.map(({ identifier, translation }) => ({
                    value: identifier,
                    label: translation || identifier
                }))}
                disabled={areIndustrialSectorsLoading || industrialSectors.length <= 0}
                label={t('industrialSector')}
                placeholder={t('selectPlaceholder', { label: t('industrialSector') })}
                readOnly={readOnly}
                required
                {...form.getInputProps('companyData.industrialSector')}
            />

            <h4 className="text-base-bold -mb-2 mt-6">{t('address')}</h4>
            <Select
                comboboxProps={{ zIndex: 9999 }}
                data={countries.map(({ id, name, countryCode }) => ({
                    value: id,
                    label: name || countryCode
                }))}
                disabled={areCountriesLoading || countries.length <= 0}
                label={t('country')}
                placeholder={t('selectPlaceholder', { label: t('country') })}
                readOnly={readOnly}
                required
                {...form.getInputProps('companyData.country')}
            />
            <div className="flex gap-4">
                <TextInput
                    className="flex-grow"
                    label={t('street')}
                    placeholder={t('enterPlaceholder', { label: t('street') })}
                    readOnly={readOnly}
                    required
                    {...form.getInputProps('companyData.street')}
                />
                <TextInput
                    className="flex-grow-0"
                    label={t('houseNumber')}
                    placeholder={t('enterPlaceholder', { label: t('houseNumber') })}
                    readOnly={readOnly}
                    required
                    {...form.getInputProps('companyData.houseNumber')}
                />
            </div>
            <div className="flex gap-4">
                <TextInput
                    className="flex-grow-0"
                    label={t('zipCode')}
                    placeholder={t('enterPlaceholder', { label: t('zipCode') })}
                    readOnly={readOnly}
                    required
                    {...form.getInputProps('companyData.zipCode')}
                />
                <TextInput
                    className="flex-grow"
                    label={t('city')}
                    placeholder={t('enterPlaceholder', { label: t('city') })}
                    readOnly={readOnly}
                    required
                    {...form.getInputProps('companyData.city')}
                />
            </div>

            <h4 className="text-base-bold -mb-2 mt-6">{t('financialInformation')}</h4>
            <div className="flex gap-4">
                <TextInput
                    className="flex-grow"
                    label={t('uidNumber')}
                    placeholder={t('enterPlaceholder', { label: t('uidNumber') })}
                    readOnly={readOnly}
                    required
                    {...uidProps}
                    error={
                        (form.isDirty('companyData.country') || form.isDirty('companyData.uidNumber') || !!uidProps.value) &&
                        form.validateField('companyData.uidNumber').error
                    }
                />
                <TextInput
                    className="flex-grow"
                    label={t('taxNumber')}
                    placeholder={t('enterPlaceholder', { label: t('taxNumber') })}
                    readOnly={readOnly}
                    {...form.getInputProps('companyData.taxNumber')}
                />
            </div>

            <h4 className="text-base-bold -mb-2 mt-6">{t('contactInformation')}</h4>
            <TextInput
                label={t('generalOfficeMail')}
                placeholder={t('enterPlaceholder', { label: t('generalOfficeMail') })}
                readOnly={readOnly}
                required
                {...form.getInputProps('companyData.generalOfficeMail')}
            />
            <div className="flex gap-4">
                <InputBase
                    className="flex-grow"
                    component={PhoneInput}
                    label={t('phoneNumber')}
                    placeholder={t('phoneNumberPlaceholder')}
                    readOnly={readOnly}
                    required
                    {...form.getInputProps('companyData.phoneNumber')}
                />
                <InputBase
                    className="flex-grow"
                    component={PhoneInput}
                    label={t('mobileNumber')}
                    placeholder={t('phoneNumberPlaceholder')}
                    readOnly={readOnly}
                    {...form.getInputProps('companyData.mobileNumber')}
                />
            </div>
        </>
    );
};
