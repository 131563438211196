/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 1.0
 */

export type StepType = typeof StepType[keyof typeof StepType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StepType = {
  InviteFromSales: 'InviteFromSales',
  DataInput: 'DataInput',
  Verification: 'Verification',
  AdditionalDataInput: 'AdditionalDataInput',
  AdditionalDocuments: 'AdditionalDocuments',
  VerificationResult: 'VerificationResult',
  SignContract: 'SignContract',
  ActivateServices: 'ActivateServices',
  GetStarted: 'GetStarted',
} as const;
