/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AssignDokaResponsibleParams,
  CreateOnboardingRequestExistingModel,
  CreateOnboardingRequestNewModel,
  GetOnboardingRequestByIdResponse,
  GetOnboardingRequestDetailResponse,
  GetOnboardingRequestsResponse,
  GetRequestsParams,
  ProblemDetails,
  UpdateOnboardingRequestModel,
  UpdateStateManuallyParams,
  VerifyOnboardingRequestModel
} from '../../models'
import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Returns all requests
 */
export const getRequests = (
    params?: GetRequestsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetOnboardingRequestsResponse>(
      {url: `/requests`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRequestsQueryKey = (params?: GetRequestsParams,) => {
    return [`/requests`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRequestsQueryOptions = <TData = Awaited<ReturnType<typeof getRequests>>, TError = ErrorType<ProblemDetails>>(params?: GetRequestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequests>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRequestsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRequests>>> = ({ signal }) => getRequests(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRequests>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRequestsQueryResult = NonNullable<Awaited<ReturnType<typeof getRequests>>>
export type GetRequestsQueryError = ErrorType<ProblemDetails>


export function useGetRequests<TData = Awaited<ReturnType<typeof getRequests>>, TError = ErrorType<ProblemDetails>>(
 params: undefined |  GetRequestsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequests>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRequests>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetRequests<TData = Awaited<ReturnType<typeof getRequests>>, TError = ErrorType<ProblemDetails>>(
 params?: GetRequestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequests>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRequests>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetRequests<TData = Awaited<ReturnType<typeof getRequests>>, TError = ErrorType<ProblemDetails>>(
 params?: GetRequestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequests>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetRequests<TData = Awaited<ReturnType<typeof getRequests>>, TError = ErrorType<ProblemDetails>>(
 params?: GetRequestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequests>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRequestsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns a request by its id
 */
export const getRequestById = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetOnboardingRequestByIdResponse>(
      {url: `/requests/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRequestByIdQueryKey = (id: string,) => {
    return [`/requests/${id}`] as const;
    }

    
export const getGetRequestByIdQueryOptions = <TData = Awaited<ReturnType<typeof getRequestById>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequestById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRequestByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRequestById>>> = ({ signal }) => getRequestById(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRequestById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRequestByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getRequestById>>>
export type GetRequestByIdQueryError = ErrorType<ProblemDetails>


export function useGetRequestById<TData = Awaited<ReturnType<typeof getRequestById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequestById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRequestById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetRequestById<TData = Awaited<ReturnType<typeof getRequestById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequestById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRequestById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetRequestById<TData = Awaited<ReturnType<typeof getRequestById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequestById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetRequestById<TData = Awaited<ReturnType<typeof getRequestById>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequestById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRequestByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns a request in detail
 */
export const getRequestDetail = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetOnboardingRequestDetailResponse>(
      {url: `/requests/${id}/detail`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRequestDetailQueryKey = (id: string,) => {
    return [`/requests/${id}/detail`] as const;
    }

    
export const getGetRequestDetailQueryOptions = <TData = Awaited<ReturnType<typeof getRequestDetail>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequestDetail>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRequestDetailQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRequestDetail>>> = ({ signal }) => getRequestDetail(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRequestDetail>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRequestDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getRequestDetail>>>
export type GetRequestDetailQueryError = ErrorType<ProblemDetails>


export function useGetRequestDetail<TData = Awaited<ReturnType<typeof getRequestDetail>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequestDetail>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRequestDetail>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetRequestDetail<TData = Awaited<ReturnType<typeof getRequestDetail>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequestDetail>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRequestDetail>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetRequestDetail<TData = Awaited<ReturnType<typeof getRequestDetail>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequestDetail>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetRequestDetail<TData = Awaited<ReturnType<typeof getRequestDetail>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRequestDetail>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRequestDetailQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Creates a new request for existing customer
 */
export const createRequestForExistingCustomer = (
    createOnboardingRequestExistingModel: CreateOnboardingRequestExistingModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetOnboardingRequestDetailResponse>(
      {url: `/requests/existing`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOnboardingRequestExistingModel
    },
      options);
    }
  


export const getCreateRequestForExistingCustomerMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRequestForExistingCustomer>>, TError,{data: CreateOnboardingRequestExistingModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createRequestForExistingCustomer>>, TError,{data: CreateOnboardingRequestExistingModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createRequestForExistingCustomer>>, {data: CreateOnboardingRequestExistingModel}> = (props) => {
          const {data} = props ?? {};

          return  createRequestForExistingCustomer(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateRequestForExistingCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof createRequestForExistingCustomer>>>
    export type CreateRequestForExistingCustomerMutationBody = CreateOnboardingRequestExistingModel
    export type CreateRequestForExistingCustomerMutationError = ErrorType<ProblemDetails>

    export const useCreateRequestForExistingCustomer = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRequestForExistingCustomer>>, TError,{data: CreateOnboardingRequestExistingModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof createRequestForExistingCustomer>>,
        TError,
        {data: CreateOnboardingRequestExistingModel},
        TContext
      > => {

      const mutationOptions = getCreateRequestForExistingCustomerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Creates a new request for an new customer
 */
export const createRequestForNewCustomer = (
    createOnboardingRequestNewModel: CreateOnboardingRequestNewModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetOnboardingRequestDetailResponse>(
      {url: `/requests/new`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createOnboardingRequestNewModel
    },
      options);
    }
  


export const getCreateRequestForNewCustomerMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRequestForNewCustomer>>, TError,{data: CreateOnboardingRequestNewModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createRequestForNewCustomer>>, TError,{data: CreateOnboardingRequestNewModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createRequestForNewCustomer>>, {data: CreateOnboardingRequestNewModel}> = (props) => {
          const {data} = props ?? {};

          return  createRequestForNewCustomer(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateRequestForNewCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof createRequestForNewCustomer>>>
    export type CreateRequestForNewCustomerMutationBody = CreateOnboardingRequestNewModel
    export type CreateRequestForNewCustomerMutationError = ErrorType<ProblemDetails>

    export const useCreateRequestForNewCustomer = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRequestForNewCustomer>>, TError,{data: CreateOnboardingRequestNewModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof createRequestForNewCustomer>>,
        TError,
        {data: CreateOnboardingRequestNewModel},
        TContext
      > => {

      const mutationOptions = getCreateRequestForNewCustomerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Saves an request if the validation succeeds
 */
export const submitRequest = (
    id: string,
    updateOnboardingRequestModel: UpdateOnboardingRequestModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/requests/${id}/submit`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateOnboardingRequestModel
    },
      options);
    }
  


export const getSubmitRequestMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitRequest>>, TError,{id: string;data: UpdateOnboardingRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof submitRequest>>, TError,{id: string;data: UpdateOnboardingRequestModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitRequest>>, {id: string;data: UpdateOnboardingRequestModel}> = (props) => {
          const {id,data} = props ?? {};

          return  submitRequest(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SubmitRequestMutationResult = NonNullable<Awaited<ReturnType<typeof submitRequest>>>
    export type SubmitRequestMutationBody = UpdateOnboardingRequestModel
    export type SubmitRequestMutationError = ErrorType<ProblemDetails>

    export const useSubmitRequest = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitRequest>>, TError,{id: string;data: UpdateOnboardingRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof submitRequest>>,
        TError,
        {id: string;data: UpdateOnboardingRequestModel},
        TContext
      > => {

      const mutationOptions = getSubmitRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Sets the verification of an request to the given verify state
 */
export const verifyRequest = (
    id: string,
    verifyOnboardingRequestModel: VerifyOnboardingRequestModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/requests/${id}/verify`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: verifyOnboardingRequestModel
    },
      options);
    }
  


export const getVerifyRequestMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyRequest>>, TError,{id: string;data: VerifyOnboardingRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof verifyRequest>>, TError,{id: string;data: VerifyOnboardingRequestModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyRequest>>, {id: string;data: VerifyOnboardingRequestModel}> = (props) => {
          const {id,data} = props ?? {};

          return  verifyRequest(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type VerifyRequestMutationResult = NonNullable<Awaited<ReturnType<typeof verifyRequest>>>
    export type VerifyRequestMutationBody = VerifyOnboardingRequestModel
    export type VerifyRequestMutationError = ErrorType<ProblemDetails>

    export const useVerifyRequest = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyRequest>>, TError,{id: string;data: VerifyOnboardingRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof verifyRequest>>,
        TError,
        {id: string;data: VerifyOnboardingRequestModel},
        TContext
      > => {

      const mutationOptions = getVerifyRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Saves a draft of an request without validating
 */
export const saveRequestDraft = (
    id: string,
    updateOnboardingRequestModel: UpdateOnboardingRequestModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/requests/${id}/save`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateOnboardingRequestModel
    },
      options);
    }
  


export const getSaveRequestDraftMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveRequestDraft>>, TError,{id: string;data: UpdateOnboardingRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof saveRequestDraft>>, TError,{id: string;data: UpdateOnboardingRequestModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveRequestDraft>>, {id: string;data: UpdateOnboardingRequestModel}> = (props) => {
          const {id,data} = props ?? {};

          return  saveRequestDraft(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveRequestDraftMutationResult = NonNullable<Awaited<ReturnType<typeof saveRequestDraft>>>
    export type SaveRequestDraftMutationBody = UpdateOnboardingRequestModel
    export type SaveRequestDraftMutationError = ErrorType<ProblemDetails>

    export const useSaveRequestDraft = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveRequestDraft>>, TError,{id: string;data: UpdateOnboardingRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveRequestDraft>>,
        TError,
        {id: string;data: UpdateOnboardingRequestModel},
        TContext
      > => {

      const mutationOptions = getSaveRequestDraftMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Updates the state of a request manually, so its possible to skip states. Only for test purposes.
 */
export const updateStateManually = (
    id: string,
    params: UpdateStateManuallyParams,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/requests/${id}/state`, method: 'POST',
        params
    },
      options);
    }
  


export const getUpdateStateManuallyMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStateManually>>, TError,{id: string;params: UpdateStateManuallyParams}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateStateManually>>, TError,{id: string;params: UpdateStateManuallyParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateStateManually>>, {id: string;params: UpdateStateManuallyParams}> = (props) => {
          const {id,params} = props ?? {};

          return  updateStateManually(id,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateStateManuallyMutationResult = NonNullable<Awaited<ReturnType<typeof updateStateManually>>>
    
    export type UpdateStateManuallyMutationError = ErrorType<ProblemDetails>

    export const useUpdateStateManually = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStateManually>>, TError,{id: string;params: UpdateStateManuallyParams}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateStateManually>>,
        TError,
        {id: string;params: UpdateStateManuallyParams},
        TContext
      > => {

      const mutationOptions = getUpdateStateManuallyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Validates an request without saving it
 */
export const validateRequest = (
    id: string,
    updateOnboardingRequestModel: UpdateOnboardingRequestModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/requests/${id}/validate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateOnboardingRequestModel
    },
      options);
    }
  


export const getValidateRequestMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validateRequest>>, TError,{id: string;data: UpdateOnboardingRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof validateRequest>>, TError,{id: string;data: UpdateOnboardingRequestModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof validateRequest>>, {id: string;data: UpdateOnboardingRequestModel}> = (props) => {
          const {id,data} = props ?? {};

          return  validateRequest(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ValidateRequestMutationResult = NonNullable<Awaited<ReturnType<typeof validateRequest>>>
    export type ValidateRequestMutationBody = UpdateOnboardingRequestModel
    export type ValidateRequestMutationError = ErrorType<ProblemDetails>

    export const useValidateRequest = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validateRequest>>, TError,{id: string;data: UpdateOnboardingRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof validateRequest>>,
        TError,
        {id: string;data: UpdateOnboardingRequestModel},
        TContext
      > => {

      const mutationOptions = getValidateRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Assigns a Doka responsible to an request
 */
export const assignDokaResponsible = (
    id: string,
    params: AssignDokaResponsibleParams,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/requests/${id}/assign-doka-responsible`, method: 'PUT',
        params
    },
      options);
    }
  


export const getAssignDokaResponsibleMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignDokaResponsible>>, TError,{id: string;params: AssignDokaResponsibleParams}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof assignDokaResponsible>>, TError,{id: string;params: AssignDokaResponsibleParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof assignDokaResponsible>>, {id: string;params: AssignDokaResponsibleParams}> = (props) => {
          const {id,params} = props ?? {};

          return  assignDokaResponsible(id,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AssignDokaResponsibleMutationResult = NonNullable<Awaited<ReturnType<typeof assignDokaResponsible>>>
    
    export type AssignDokaResponsibleMutationError = ErrorType<ProblemDetails>

    export const useAssignDokaResponsible = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignDokaResponsible>>, TError,{id: string;params: AssignDokaResponsibleParams}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof assignDokaResponsible>>,
        TError,
        {id: string;params: AssignDokaResponsibleParams},
        TContext
      > => {

      const mutationOptions = getAssignDokaResponsibleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    